import React, { useCallback, useContext, useEffect, useState } from 'react';
import * as S from './LockInSelectorV2.styles';
import FunnelContext from '../../context/FunnelContext';
import { ProductPrice } from '../../utils/productPrice';

const LockInSelector = props => {
  const { children, periods } = props;

  const {
    extraObjects,
    setExtraObjects,
    currentCategory,
    setCurrentCategory,
    currentProduct,
    setCurrentProduct,
    currency,
    subscription,
    onetime,
    setTubIndexChecked,
    currentTubIndex
  } = useContext(FunnelContext);

  const currencySymbol = currency?.symbol || `$`;
  const [currentPeriod, setCurrentPeriod] = useState('1-month');
  const [init, setInit] = useState(false);

  const ProductData = useCallback(
    (category, filterByTag) => {
      let productData = [];

      if (category === 'subscriptions') {
        productData = subscription?.products.map((product, index) => {
          product.index = index;
          return product;
        });
      } else {
        productData = onetime?.products.map((product, index) => {
          product.index = index;
          return product;
        });
      }

      if (filterByTag) {
        productData = productData.filter(({ tags }) => {
          return tags.includes(filterByTag);
        });
      }

      if (category === 'subscriptions') {
        productData = productData?.filter((product, i) => {
          return currentProduct === `subscription_${i}`;
        });
      } else {
        productData = productData?.filter((product, i) => {
          return currentProduct === `onetime_${i}`;
        });
      }

      const [data] = productData;
      return data;
    },
    [subscription, onetime, currentProduct]
  );

  useEffect(() => {
    if (!init) {
      setInit(true);
      let [selected] = periods.filter(item => item.selected);
      if (!selected) {
        if (periods[0]) {
          setCurrentPeriod(periods[0]?.value ?? '1-month');
        }
      } else {
        setCurrentPeriod(selected.value ?? '1-month');
        setExtraObjects(
          Object.assign(extraObjects, {
            sub_lock_in_enabled: true,
            sub_lock_in_schedule: selected.value ?? '1-month'
          })
        );
        const category = selected.category ?? 'subscriptions';
        setCurrentCategory(category);
        setCurrentProduct(
          `${
            category === 'subscriptions' ? 'subscription' : 'onetime'
          }_${currentTubIndex}`
        );
      }
    }
    const productData = ProductData(currentCategory, currentPeriod);
    if (productData) {
      setTubIndexChecked(true);
      setExtraObjects(
        Object.assign(extraObjects, {
          tub_index_override: productData.index
        })
      );
    }
  }, [
    init,
    setInit,
    periods,
    extraObjects,
    setExtraObjects,
    setCurrentPeriod,
    currentCategory,
    currentProduct,
    currentPeriod,
    ProductData,
    setTubIndexChecked,
    setCurrentCategory,
    setCurrentProduct,
    currentTubIndex
  ]);

  const handleClick = selected => {
    setCurrentPeriod(selected.value ?? '1-month');
    setExtraObjects(
      Object.assign(extraObjects, {
        sub_lock_in_enabled: true,
        sub_lock_in_schedule: selected.value ?? '1-month'
      })
    );
    setTubIndexChecked(true);
    setExtraObjects(
      Object.assign(extraObjects, {
        tub_index_override: selected?.productData?.index ?? 0
      })
    );
    const category = selected.category ?? 'subscriptions';
    setCurrentCategory(category);
    setCurrentProduct(
      `${
        category === 'subscriptions' ? 'subscription' : 'onetime'
      }_${currentTubIndex}`
    );
  };

  const templateText = (
    text,
    chewsPerTub,
    lockin,
    periodLength,
    category,
    productId,
    isMathFloor,
    isWholePeriod
  ) => {
    let price = ProductPrice({
      type: `price`,
      currentCategory: category,
      currentProduct: productId,
      filterByTag: lockin
    });
    let quantity = ProductPrice({
      type: `quantity`,
      currentCategory: category,
      currentProduct: productId,
      filterByTag: lockin
    });
    price = isWholePeriod ? price : price / periodLength;
    let discounted_price = ProductPrice({
      type: `discounted_price`,
      currentCategory: category,
      currentProduct: productId,
      filterByTag: lockin
    });
    discounted_price = isWholePeriod
      ? discounted_price
      : discounted_price / periodLength;
    let price_per_chew =
      parseFloat(discounted_price) / parseInt(chewsPerTub) / quantity;

    if (isMathFloor) {
      price_per_chew = Math.floor(price_per_chew * 100) / 100;
    }

    let newText = text;
    newText = newText?.replace(/\[price\]/gm, parseFloat(price).toFixed(2));
    newText = newText?.replace(
      /\[discounted-price\]/gm,
      parseFloat(discounted_price).toFixed(2)
    );
    newText = newText?.replace(/\[currency-symbol\]/gm, currencySymbol);
    newText = newText?.replace(
      /\[price-per-chew\]/gm,
      parseFloat(price_per_chew).toFixed(2)
    );

    return newText;
  };

  return (
    <S.Container>
      <S.ListContainer>
        {periods.map((item, index) => {
          const {
            isWholePeriodMainPrice,
            isWholePeriodSubPrice,
            isMathFloorMainPrice,
            isMathFloorSubPrice
          } = item;
          const category = item.category ?? 'subscriptions';
          const productId = `${
            category === 'subscriptions' ? 'subscription' : 'onetime'
          }_${currentTubIndex}`;
          const chewsPerTub = item.chewsPerTub ?? 30;
          const lockin = item.value ?? '1-month';
          const periodLength = item.periodLength ?? 1;
          const mainPrice = templateText(
            item.mainPrice,
            chewsPerTub,
            lockin,
            periodLength,
            category,
            productId,
            isMathFloorMainPrice,
            isWholePeriodMainPrice
          );
          const subPrice = templateText(
            item.subPrice,
            chewsPerTub,
            lockin,
            periodLength,
            category,
            productId,
            isMathFloorSubPrice,
            isWholePeriodSubPrice
          );
          item.productData = ProductPrice({
            type: `data`,
            currentCategory: category,
            currentProduct: productId,
            filterByTag: lockin
          });

          const NoChildComponent = () => {
            return (
              <>
                <S.Row className="top">
                  <S.Left className="bold title">
                    {item.title}
                    <span className="discount">{item.titleDiscount}</span>
                  </S.Left>
                  <S.Right className="bold main-price">{mainPrice}</S.Right>
                </S.Row>
                <S.Row>
                  <S.Left className="subtitle">{item.subTitle}</S.Left>
                  <S.Right>{subPrice}</S.Right>
                </S.Row>
              </>
            );
          };

          return (
            <S.ListItem
              onClick={() => handleClick(item)}
              key={index}
              className={`${item.value === currentPeriod ? 'active' : ''}`}
            >
              <S.InputRadio
                className={`${item.value === currentPeriod ? 'active' : ''}`}
              ></S.InputRadio>
              {(children && children[index]) ?? NoChildComponent()}
            </S.ListItem>
          );
        })}
      </S.ListContainer>
    </S.Container>
  );
};

export default LockInSelector;
